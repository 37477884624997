@media screen and (min-width: 1024px) {
  .question-playing #fontsize-button, .question-playing .question_outer .helper-btn, .question-playing .soundControl, .question-playing .muteControl, .question-playing #help-button {
    position: absolute;
    top: auto;
    bottom: 26px;
  }
  .question-playing #header .helper-btn {
    left: auto;
    position: absolute;
    bottom: 0;
  }
  .question-playing #header #fontsize-button {
    left: 28px;
    bottom: 26px;
    background-image: url(../img/fontsize-button.png);
  }
  .question-playing .soundControl {
    left: 100px;
  }
  .question-playing .muteControl {
    left: 172px;
  }
  #help-button {
    left: 244px;
  }
  #journal-button {
    left: 316px;
  }
  #viewbg-button {
    left: 388px;
  }
  #fontControls {
    top: auto;
    left: 28px;
    bottom: 84px;
  }
}

@media only screen and (max-width: 1600px) {
  body {
    font-size: 13px;
  }
  .journal-wrapper .pages-content h2 {
    margin: 0 0 .5em;
  }
  .journal-wrapper .pages-content .content-inner {
    font-size: 1.15em;
  }
  #narration_end_arrow {
    max-height: 77.2%;
    right: -12%;
  }
}

@media only screen and (max-width: 1400px) and (min-width: 1025px) {
  #submitLang {
    margin-top: 5%;
  }
}

@media only screen and (max-width: 1280px) {
  body {
    font-size: 12px;
  }
}

@media only screen and (max-width: 1024px) and (min-width: 768px), only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  html, body, #login, #headphones-loader, .container, main, .page.visible, .question_outer, .question_wrapper, .question_col, .answers_wrapper, .question_main_wrapper, .avatar_large, #world-map-container, .avatar_large_wrapper, .book_container {
    height: 75vw;
  }
  .container.no-overflow{
    overflow-y: auto;
  }
  #headphones-loader {
    background-image: url(../img/headphones-bg-tablet.jpg);
  }
  #avatar_selection {
    background: url(../img/avatar-selection-bg-tablet.jpg);
  }
  #end_screen {
    background-image: url(../img/congratulations-bg-tablet.jpg);
  }
  #book_screen {
    background-image: url(../img/book-screen-bg-tablet.jpg);
  }
  #login, #world-map-container {
    background-image: url(../img/world-map-bg.jpg);
    /* todo */
    background-size: cover;
    background-position: center;
  }
  #avatar_selection .avatar_large img {
    width: 100%;
  }
  .progress_pointer:before {
    top: -24px;
    width: 22px;
    height: 22px;
    padding: 3px 0;
  }
  .progress_pointer:after {
    height: 18px;
    margin-top: -7px;
  }
  .progress_pointer_wrap {
    font-size: 13px;
    top: 51%;
    height: 5%;
  }
  .progress-title {
    top: 12.5%;
    font-size: 11px;
  }
  .question-playing #header #fontsize-button {
    left: 18px;
    bottom: 22px;
  }
  .question-playing .soundControl {
    left: 68px;
  }
  .question-playing .muteControl {
    left: 118px;
  }
  #help-button {
    left: 168px;
  }
  #journal-button {
    left: 218px;
  }
  #viewbg-button {
    left: 268px;
  }
  #fontControls {
    left: 18px;
  }
}

@media only screen and (max-width: 1024px), only screen and (max-device-width: 1024px) {
  .page.visible {
    overflow: hidden;
    position: relative;
  }
  .soundControl {
    top: 8px;
  }
  .muteControl {
    top: 52px;
  }
  #help-button {
    top: 96px;
  }
  #fontsize-button {
    top: 140px;
    height: 44px;
  }
  #fontControls {
    width: 140px;
    height: 145px;
    background-size: 100% auto;
    bottom: 63px;
  }
  #fontControls a {
    padding: 6px 5px;
  }
  .fullscreenControl {
    padding: 1% 0.6%;
    z-index: 99;
  }
  #narration_end_arrow {
    display: none !important;
  }
  .login_container {
    padding: 9% 0 0;
    max-width: 50%;
    max-height: 64.2%;
  }
  .lang_wrap {
    max-width: 46%;
    max-height: 66.4%;
  }
  .lang_header {
    margin-bottom: 7%;
  }
  .helper-btn {
    width: 38px;
    height: 37px;
    left: 8px;
  }
  #book.wowbook {
    left: 13%;
    top: 8.2%;
    max-width: 75.2%;
  }
  .book_narration_actions {
    bottom: 15.1%;
    left: 52%;
    width: 35.8%;
    height: 5.8%;
  }
  .hexagon:not(.chapter-hexagon) {
    width: 182px;
    height: 157px;
    max-width: 100%;
    max-height: 100%;
  }
  .hexagon:nth-child(3n + 2):not(.chapter-hexagon) {
    margin-left: -44px;
  }
  .hexagon:nth-child(2):not(.chapter-hexagon) {
    margin-top: 78px;
  }
  .hexagon:nth-child(3n + 3):not(.chapter-hexagon) {
    margin-top: -78px;
    margin-left: -44px;
  }
  .hexagon:nth-child(3):not(.chapter-hexagon) {
    margin-top: 0;
  }
  .hexagon:nth-child(3n + 4):not(.chapter-hexagon) {
    margin-top: -78px;
    clear: left;
  }
  .hexagon:last-child:not(.chapter-hexagon) {
    margin-top: -78px;
    margin-left: 94px;
  }
  .avatars_grid {
    margin-top: 38px;
    width: 484px;
    max-width: 100%;
    padding-right: 0;
  }
  #avatar_selection .avatar_large {
    max-width: 57%;
    height: 100%;
  }
  #avatar_submit {
    margin: 7% 0 0 28.4%;
    height: 72px;
    max-height: 100%;
  }
  .avatars_grid h1 {
    left: 122px;
  }
  .avatars_grid:after {
    right: 1%;
  }
  .avatar_descr {
    width: 100%;
    margin: 0;
  }
  .avatar_details {
    width: 96%;
    margin: 0;
    padding: 40px 20px;
  }
  .avatar_type {
    width: 96%;
  }
  .avatar_default_name {
    font-size: 2.2em;
    height: 100px;
    width: 100%;
  }
  .headphones {
    max-width: 30%;
  }
  .headphones-box {
    padding: 3% 2% 2% 2.7%;
  }
  .dash {
    visibility: hidden !important;
  }
  .question_main {
    max-height: 48%;
  }
  .question_main_wrapper {
    margin: 0 0 0 28%;
    max-width: 72%;
  }
  .cell-8-col.question_col {
    width: 63.6%;
    padding: 0 0.4% 0 0;
  }
  .cell-4-col.question_col {
    width: 36%;
    padding: 0;
  }
  .player_name {
    max-width: 29%;
    bottom: 0;
    height: 158px;
  }
  .question_avatar {
    bottom: -21%;
    left: -13%;
    max-width: 56%;
    overflow: hidden;
  }
  #question_avatar2 {
    left: -17%;
  }
  #question_avatar3 img {
    margin-left: -5%;
  }
  #question_avatar4 {
    bottom: -2%;
    max-width: 66%;
  }
  #question_avatar6 {
    max-width: 80%;
  }
  #question_avatar7 img {
    margin-left: -4%;
  }
  #question_avatar8 {
    bottom: -14%;
    max-width: 60%;
  }
  #question_avatar8 img {
    margin-left: 18%;
  }
  .progress_bar_outer {
    margin-top: 7%;
  }
  .answers_wrapper {
    padding: 9% 0 0;
  }
  .answer_draggables {
    height: 10.5%;
  }
  .answer_draggables img {
    max-width: 28%;
  }
  .answer_draggables h4 {
    font-size: 1.7em;
  }
  .answer:before, .answer.rejected:after, .answer.selected:after {
    max-width: 14%;
    right: 0.5%;
  }
  .answer {
    max-height: 22.1%;
  }
  .answer .answer_inner {
    width: 75.2%;
  }
  .question_text_container {
    max-height: 67%;
    padding: 5% 10% 0 11%;
  }
  .question_main .narration_actions {
    bottom: -16%;
    height: 12.4%;
    padding: 8px 14%;
  }
  .answer_inner .mCSB_scrollTools {
    width: 4px;
  }
  .answer_inner .mCSB_inside>.mCSB_container {
    margin-right: 9px;
  }
  .answer_submit_wrapper {
    height: 11.3%;
    margin-top: -10%;
  }
  #answer_submit {
    margin: 1.6% 0 0 20%;
    max-width: 40%;
    max-height: 54%;
  }
  .question_text p {
    padding: 0 4% 18px 6%;
  }
  .help_txt {
    max-height: 15.6vw;
  }
  .avatar_small {
    cursor: pointer;
  }
  .helper-btn:hover:after, #help-button:hover, #journal-button:hover, #fontsize-button:hover {
    box-shadow: none;
  }
  .img-hover-btn:hover:after {
    opacity: 0;
  }
  .video_actions {
    width: 29.2%;
  }
  .congratulations-box {
    background-size: 100% 600px;
    max-width: none;
    max-height: none;
    width: 1000px;
    height: 600px;
  }
  .journal-wrapper {
    max-width: 88.8%;
    max-height: 73.2vh;
    margin: 10vh 0 0 3%
  }
  .chapter-portrait-wrapper {
    max-height: 58vh;
  }
  .chapters-grid {
    width: 38%;
    height: 37%;
  }
  .chapters-grid-5 {
    left: 64.2%;
  }
  .chapters-grid-8 {
    width: 33.66%;
    right: 8%;
  }
}

@media only screen and (max-width: 839px) and (min-width: 480px) {
  .cell-4-col {
    width: calc(33.33333% - 16px);
  }
}

@media only screen and (max-width: 799px) {
  .cell-4-col {
    width: calc(33.33333% - 16px);
  }
  html, body, #login, #headphones-loader, .container, main, .page.visible, .question_outer, .question_wrapper, .question_col, .question_main_wrapper, .avatar_large, #world-map-container, .avatar_large_wrapper, .book_container {
    height: 177.78vw;
  }
  .bg_screen {
    background-size: cover !important;
    background-position: center !important;
  }
  .login_container {
    max-width: 100%;
    max-height: 54%;
  }
  .lang_wrap {
    max-width: 90%;
  }
  .login_form input {
    max-height: 12.4%;
  }
  .login_logo {
    max-width: 43.2%;
    max-height: 24.6%;
    margin: 9% auto 0;
  }
  .check-radio {
    font-size: 2.1em;
  }
  .check-radio>.img-btn {
    width: 30px;
    height: 30px;
  }
  #submitLang {
    margin-top: 6%;
    max-width: 52%;
  }
  #submitLogin {
    max-height: 15.4%;
  }
  .wowbook-page.wowbook-right {
    left: 0 !important;
  }
  #book.wowbook {
    left: 0;
    max-width: 200%;
  }
  .book_narration_actions {
    left: 14%;
    width: 84%;
    height: 6%;
    bottom: 25%;
  }
  #headphones-loader {
    text-align: center;
    padding: 46% 0 0;
    box-sizing: border-box;
  }
  .headphones {
    max-width: 46%;
  }
  .headphones-box {
    max-width: 96%;
    max-height: 70%;
    top: 45%;
    left: 2%;
    padding: 6.2% 2% 2% 2.7%;
    font-size: 1.45em;
  }
  .cell-8-col.question_col {
    width: 75%;
    padding: 0;
    height: 20%;
  }
  .question_main_wrapper {
    margin: 0 0 0 10%;
    max-width: 80%;
    height: 100%;
  }
  .progress_bar_outer {
    margin: 0.7% 0 0.3% 1.6%;
    max-height: 16%;
  }
  .question_text_container {
    max-height: 58%;
    padding: 4.8% 2% 0 3%;
  }
  .question_text {
    font-size: 1em;
  }
  .question_text p {
    line-height: 1.2em;
  }
  .cell-4-col.question_col {
    width: 100%;
    z-index: 1;
    position: relative;
  }
  .soundControl {
    top: 0.6%;
  }
  .muteControl {
    top: 6.4%;
  }
  .helper-btn {
    left: 1.4%;
    max-width: 9%;
    max-height: 5.2%;
  }
  #help-button {
    top: 12.4%;
  }
  #fontsize-button {
    top: 18.2%;
  }
  #fontControls {
    left: 1.2%;
    top: 18.4%;
    max-width: 34%;
    max-height: 20%;
    background-repeat: no-repeat;
  }
  #fontControls a {
    font-size: 1.4em;
    line-height: 1.5em;
  }
  .answers_wrapper {
    padding: 0;
    height: 67%;
  }
  .answers {
    float: right;
    width: 86%;
    height: 68%;
  }
  .answer .answer_inner {
    width: 71.2%;
  }
  .answer_draggables img {
    max-width: 100%;
    margin: 36% 0;
  }
  .answer_draggables {
    height: auto;
    float: left;
    width: 13%;
  }
  .player_name, .answer_draggables h4, .avatars_grid:after, .avatar_type, .piece, .wowbook-page.wowbook-left {
    display: none !important;
  }
  .answer_draggables .cell-6-col {
    width: 100%;
  }
  .question_main {
    background-image: url(../img/question-bg-mobile.png);
    max-height: 83%;
  }
  .answer {
    background-image: url(../img/reply-bg-mobile.png);
    margin: 0.1% 0 0;
    font-size: 1em;
    max-height: 24.9%;
    width: 70%;
  }
  .answer p {
    line-height: 1.2em;
  }
  .question_main .narration_actions {
    bottom: 3.4%;
    height: 19.8%;
    padding: 8px 10%;
    width: 74%;
  }
  .video_actions {
    width: 70px;
    height: 38px;
  }
  .video_actions a {
    max-height: 100%;
    max-width: 100%;
  }
  video#game_video {
    width: 100%;
  }
  #answer_submit {
    margin: 0.2% auto 0;
    max-width: 32%;
    background-position: 0 100%;
  }
  .answer_submit_wrapper {
    margin: 0 auto;
    clear: both;
    height: 14%;
  }
  .question_avatar {
    bottom: -20% !important;
    left: 10%;
    max-width: 100% !important;
    max-height: 86%;
    width: 90%;
  }
  #question_avatar2 {
    left: 2%;
  }
  #question_avatar4 img {
    margin-left: 4%;
  }
  #question_avatar6 img {
    margin-left: 6%;
  }
  #question_avatar7 img, #question_avatar8 img {
    margin-left: 14%;
  }
  #avatar_selection .cell {
    width: 100%;
  }
  .avatars_grid {
    margin-top: 3%;
    width: 100%;
  }
  .avatars_grid h1 {
    left: 0;
    position: relative;
    width: 100%;
    top: auto;
    padding: 0;
    margin: 0 0 0.3em;
    font-size: 2em;
  }
  .hexagon {
    width: 27vw;
    height: 23.1vw;
    margin: 0 !important;
  }
  .hexagon:nth-child(odd) {
    float: left;
    clear: both;
  }
  .hexagon:nth-child(even) {
    float: right;
    clear: none !important;
  }
  #avatar_selection .avatar_large_wrapper, #avatar_selection .avatar_large {
    width: 100%;
  }
  #avatar_selection .avatar_large {
    max-width: 100%;
    text-align: center;
    z-index: 0;
  }
  #avatar_selection .avatar_large img {
    margin: 0 !important;
    max-height: 90%;
    position: absolute;
    bottom: 0;
    left: 50%;
    -moz-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  #avatar_selection #avatar_large1 img {
    left: 66%;
  }
  #avatar_selection #avatar_large3 img {
    left: 37%;
  }
  #avatar_selection #avatar_large6 img {
    max-width: 150%;
    left: 44%;
  }
  .avatar_default_name {
    font-size: 2em;
    width: 45%;
    position: relative;
    max-height: 20vw;
    margin: -7% auto 0;
    padding: 7.6% 0 0;
    height: 110px;
  }
  #avatar_descr4 .avatar_default_name {
    font-size: 1.9em;
    padding: 7.6% 0 0;
  }
  .avatar_details {
    width: 100%;
    max-width: 100%;
    font-size: 1.2em;
    padding: 2px 10px;
    max-height: 21vh;
    overflow-y: auto;
  }
  .avatar_details p {
    margin-bottom: 0.2em;
    line-height: 1.3em;
  }
  .avatar_details:before, .avatar_details:after {
    position: relative;
    margin: 0 auto;
    left: 0;
  }
  .avatar_details:before {
    top: 7px;
    margin-bottom: 8px;
  }
  .avatar_details:after {
    bottom: 0;
  }
  #avatar_submit {
    margin: 0 0 0 32%;
    height: 10vh;
    max-height: 100%;
    position: fixed;
    bottom: -0.4%;
    background-position: 0 100%;
  }
  .help_txt {
    max-height: 24.4vw;
    max-width: 23vw !important;
  }
  #answer_draggables_help .info-button {
    max-width: 8vw;
    left: 15%;
    top: 36%;
  }
  #answer_draggables_help .help_txt {
    top: 52.5%;
    padding: 20%;
  }
  .qtip-tipsy {
    font-size: 0.9em;
  }
  .qtip-tipsy .qtip-content {
    padding: 6px 8px;
  }
}

@media only screen and (max-width: 600px) {
  .wowbook-page-content {
    background: none;
    filter: none;
    padding-bottom: 6%;
  }

  .fullscreenControl {
    padding: 2.6% 2%;
  }
  #book.wowbook {
    left: 13%;
    max-width: 174%;
  }
  .book_narration_actions {
    left: 14%;
    width: 84%;
    height: 6%;
    bottom: 20%;
  }
  .answers_wrapper {
    height: 94%;
  }
  .answer {
    width: 100%;
  }
  .cell-8-col.question_col {
    width: 100%;
    padding: 0;
    height: 26.4%;
  }
  .avatars_grid h1 {
    font-size: 1.7em;
    position: absolute;
    left: 0;
    top: 0;
  }
  .avatar_default_name {
    font-size: 1.4em;
    width: 60%;
    padding: 9.2% 0 0;
  }
  #avatar_descr4 .avatar_default_name {
    font-size: 1.3em;
    padding: 9.8% 0 0;
  }
  .hexagon:not(.chapter-hexagon) {
    width: 91px;
    height: 78px;
  }
  #avatar_submit {
    margin: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 48px;
    bottom: 3%;
    background-position: top;
    z-index: 1;
  }
  .answer_draggables img {
    margin: 60% 0;
  }
  .answer_submit_wrapper {
    height: 10.2%;
  }
  .help_txt {
    max-height: 28.4vw;
    max-width: 28vw !important;
  }
  #world-map-container.island1 {
    background-image: url(../img/Map-final_Oracle.jpg);
  }
  #world-map-container.island2 {
    background-image: url(../img/Map-final_Temperu.jpg);
  }
  #world-map-container.island3 {
    background-image: url(../img/Map-final_Fabella.jpg);
  }
  #world-map-container.island4 {
    background-image: url(../img/Map-final_Sylvus.jpg);
  }
  .cloud {
    background-image: url(../img/mobile-cloud.png);
    width: 100%;
    height: 100%;
    background-size: cover;
    max-width: 100%;
    left: 0;
    top: 0;
    right: auto;
  }
  .mCS-inset-dark.mCSB_scrollTools {
    right: 10px;
  }
  .mCS-inset-dark.mCSB_scrollTools .mCSB_draggerRail {
    background-size: 20px 100%;
  }
  .congratulations-box {
    position: relative;
    top: 50%;
    transform: translateY(-30%);
    -webkit-transform: translateY(-30%);
    max-width: 120%;
    margin: 0 -10%;
    padding: 3.5% 0;
    font-size: 1.1em;
    background-size: 100% 400px;
  }
  .congratulations-box p {
    padding: 1% 18%;
    overflow-y: auto;
    line-height: 1.1em;
  }
  .helper-btn {
    max-width: none;
    max-height: none;
    width: 32px;
    height: 32px;
  }
  .soundControl {
    top: 3px;
  }
  .muteControl {
    top: 38px;
  }
  #help-button {
    top: 72px;
  }
  #viewbg-button {
    top: 107px;
  }
  .bg-isolated #viewbg-button {
    left: 1.4%;
    top: 3px;
  }
  #fontsize-button {
    top: 142px;
  }
  #fontControls {
    top: 177px;
  }
  #answers_help {
    width: 98%;
    height: 98%;
  }
  #question_help {
    height: 99%;
  }
  .avatar_details:before, .avatar_details:after {
    content: none;
  }
  .progress-title, .question-island {
    display: none;
  }
  .progress_bar_outer {
    width: 98.6%;
    max-width: 98.6%;
  }
  .progress_bar {
    background: url(../img/progress-bar.png) no-repeat scroll center;
    background-size: 100% auto;
  }
  .progress_pointer_wrap {
    padding: 0 2.1% 0% 4%;
    max-width: 91.2%;
    height: 50%;
    font-size: 10px;
    top: 24%;
  }
  .progress_pointer {
    height: 24%;
  }
  .progress_pointer:before {
    top: -13px;
    width: 14px;
    height: 14px;
    max-width: 14px;
    max-height: 14px;
    padding: 2px 0;
  }
  .progress_pointer:after {
    height: 6px;
    margin-top: -1px;
  }
  #journal-button {
    display: none;
  }
}

@media only screen and (orientation: portrait) and (max-width: 1024px) and (min-width: 768px), only screen and (orientation: portrait) and (min-device-width: 768px) and (max-device-width: 1024px) {
  #orientationRotateMsg {
    display: block;
    background-image: url(../img/rotate-tablet-to-landscape-white.png);
  }
  #orientationRotateMsg p {
    bottom: 28%;
    font-size: 2em;
  }
}

@media only screen and (orientation: landscape) and (min-width: 479px) and (max-width: 767px) {
  #orientationRotateMsg {
    display: block;
    background-image: url(../img/rotate-phone-to-portrait-white.png);
  }
}

@media screen and (min-color-index: 0) and(-webkit-min-device-pixel-ratio:0) and (max-device-width: 1024px) {
  .fullscreenControl {
    display: none !important;
  }
}