.width-100 { width: 100%; }

.relative { position: relative; }

.block { display: block; }
.inline { display: inline; }
.inline-block { display: inline-block; }

.float-left { float: left; }
.float-right { float: right; }

.text-left { text-align: left; }
.text-center { text-align: center; }
.text-right { text-align: right; }

.cursor-pointer { cursor: pointer; }

.margin-right-unit { margin-right: $-padding-unit; }
.margin-left-unit { margin-left: $-padding-unit; }

.fill-height-or-more {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}
.flex-basis-grow {
  flex-basis: 0;
  flex-grow: 1;
}
.flex-wrap {
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}
.flex-column {
  -webkit-flex-direction: column;
  flex-direction: column;
}
.flex-1 { flex: 1; }

.align-center {
  -webkit-align-items: center;
  align-items: center;
}
.align-baseline {
  -webkit-align-items: baseline;
  align-items: baseline;
}

.justify-center { justify-content: center; }
.justify-space-between { justify-content: space-between; }
.justify-space-around { justify-content: space-around; }
.justify-flex-end { justify-content: flex-end; }

.text-underline { text-decoration: underline; }
