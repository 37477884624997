@font-face {
  font-family: 'Maiandra GD';
  src: url('../fonts/maiandra.eot');
  src: url('../fonts/maiandra.eot#iefix') format('embedded-opentype'), url('../fonts/maiandra.woff2') format('woff2'), url('../fonts/maiandra.woff') format('woff'), url('../fonts/maiandra.ttf') format('truetype'), url('../fonts/maiandra.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

/*------------------------------------------------------------------
  0. RESET
  -------------------------------------------------------------------*/

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

ol, ul {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/*------------------------------------------------------------------
  1. UTILITY
  -------------------------------------------------------------------*/

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  /* make transparent link selection, adjust last value opacity 0 to 1.0 */
}

body {
  -webkit-touch-callout: none;
  -webkit-text-size-adjust: none;
  -webkit-user-select: none;
  background-attachment: fixed;
  font-family: 'Maiandra GD', sans-serif;
  font-size: 14px;
  line-height: 1;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

body.overflowed {
  overflow-y: auto;
}

.container {
  position: relative;
  background-color: transparent;
}

.clr {
  clear: both;
}

.left {
  float: left;
}

.right {
  float: right;
}

.no-padding {
  padding: 0 !important;
}

body, button, a, .mCSB_scrollTools .mCSB_dragger, .check-radio {
  cursor: url('../img/custom-cursor.png'), auto;
}

.img-btn.expandedLeft, .img-btn.expandedLeft:after {
  background: none !important;
  text-indent: 0;
  opacity: 0.8;
  text-align: center;
}

.img-btn i {
  font-size: 3em;
  color: #fff;
  display: inline-block;
}

.flipped {
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: 'FlipH';
}

/*------------------------------------------------------------------
  2. COLORS
  -------------------------------------------------------------------*/

html {
  background: #333333;
  width: 100%;
}

body, html {
  color: #231f20;
}

.location {
  color: #00bf56;
}

.person {
  color: #0094ff;
}

.narration, .special_item {
  color: #ff8121;
}

#book .location {
  color: #098641;
}

#book .person {
  color: #186398;
}

#book .narration, #book .special_item {
  color: #ff5722;
}

/*------------------------------------------------------------------
  3. TYPOGRAPHY
  -------------------------------------------------------------------*/

h1, h2, h3, h4, h5, h6 {
  font-family: 'Maiandra GD', sans-serif;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.12);
  line-height: 1.2em;
  margin: 0 0 0.2em;
  color: #231f20;
}

a {
  text-decoration: none;
  outline: none;
  transition: all 300ms;
}

i {
  color: #727478;
}

h1 {
  font-size: 2em;
}

h2 {
  font-size: 1.7em;
}

h3 {
  font-size: 1.29em;
}

h4 {
  font-size: 1.14em;
}

p {
  line-height: 1.5em;
}

/*------------------------------------------------------------------
  4. BASE ELEMENTS
  -------------------------------------------------------------------*/

img {
  max-width: 100%;
  height: auto;
}

ul {
  margin: 0;
  padding: 0;
  list-style-position: inside;
}

table {
  border-collapse: collapse;
  width: 100%;
}

table td {
  padding: 2px;
}

hr {
  border-top: 1px solid #4d4d4f;
  margin: 8px 0;
}

/*------------------------------------------------------------------
  5. LAYOUT
  -------------------------------------------------------------------*/

.grid {
  padding: 0;
}

.cell {
  padding: 0 8px;
  display: inline-block;
  vertical-align: top;
  box-sizing: border-box;
}

.cell-1-5-col {
  width: 16.66%;
}

.cell-2-col {
  width: 19%;
}

.cell-3-col {
  width: 25%;
}

.cell-4-col {
  width: 33.33%;
}

.cell-5-col {
  width: 41.66%;
}

.cell-6-col {
  width: 50%;
}

.cell-7-col {
  width: 58.33%;
}

.cell-8-col {
  width: 66.66%;
}

.cell-9-col {
  width: 75%;
}

.cell-10-col {
  width: 81%;
}

.cell-12-col {
  width: 100%;
}

/* 6. Login
  -------------------------------------------------------------------*/

#login {
  background: url(../img/world-map-bg.jpg) no-repeat scroll;
}

.login_container {
  background: url(../img/login-overlay-bg.png) no-repeat scroll;
  padding: 6.4% 0 0;
  width: 665px;
  height: 753px;
  max-width: 34.7%;
  max-height: 59%;
  box-sizing: border-box;
  margin: 0 auto;
}

.login_form {
  height: 100%;
}

#lang_form {
  height: 55%;
}

.lang_wrap {
  background: url(../img/lang-select-bg.png) no-repeat scroll;
  padding: 1.6% 0 0;
  width: 544px;
  height: 645px;
  max-width: 31%;
  max-height: 80%;
  box-sizing: border-box;
  margin: 0 auto;
  z-index: 99;
  position: relative;
  top: 30%;
  transform: translateY(-30%);
  -webkit-transform: translateY(-30%);
}

.login_form input {
  display: block;
  width: 454px;
  height: 73px;
  max-width: 68.2%;
  max-height: 14.4%;
  font-size: 1.1em;
  line-height: 1em;
  color: #fff;
  background: url(../img/login-input-bg.png) no-repeat scroll;
  border: none;
  outline: 0;
  box-sizing: border-box;
  margin: 0 auto 2.2%;
  padding: 0 8%;
}

.login_form input:-webkit-autofill {
  animation-name: loginautofill;
  -webkit-animation-name: loginautofill;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
}

.login_logo {
  height: 140px;
  width: 300px;
  max-width: 45.2%;
  max-height: 27.6%;
  margin: 6px auto 0;
  background: url('../img/logo.png') scroll no-repeat 0 25px transparent;
  padding-top: 25px;
}

.lang_header {
  height: 72px;
  width: 450px;
  max-width: 83%;
  max-height: 11.8%;
  margin: 5.4% auto 7%;
  padding: 0;
  background: url('../img/select-lang-header.png') scroll no-repeat transparent;
}

.login_error {
  font-size: 1.25em;
  line-height: 1.3em;
  color: #fff;
  text-align: center;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.75);
  margin-top: -1.2%;
}

@keyframes loginautofill {
  to {
    color: #fff;
    background: url(../img/login-input-bg.png) no-repeat scroll;
    background-size: 100% auto;
  }
}

@-webkit-keyframes loginautofill {
  to {
    color: #fff;
    background: url(../img/login-input-bg.png) no-repeat scroll;
    background-size: 100% auto;
  }
}

#headphones-loader {
  background: url(../img/headphones-bg.jpg) no-repeat scroll;
  text-align: center;
  padding: 7% 0;
}

.headphones {
  z-index: 9;
  position: relative;
  max-width: 23.2%;
}

.headphones-box {
  background: url(../img/headphones-box-bg.png) no-repeat scroll;
  padding: 5% 2% 2% 2.7%;
  width: 855px;
  height: 255px;
  max-width: 45%;
  max-height: 24%;
  box-sizing: border-box;
  position: absolute;
  top: 35%;
  left: 27.5%;
}

.headphones-box, .congratulations-box {
  color: #fff;
  font-size: 1.7em;
  line-height: 1.6em;
}

/*.loaded #headphones-loader {display: none;}*/

/*------------------------------------------------------------------
  8. AVATAR
  -------------------------------------------------------------------*/

.avatar_small {
  text-align: center;
}

.hex_outer:after {
  /* background-image: url(../img/avatar-highlight.png); */
  width: 326px;
  height: 297px;
  display: block;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
}

.avatar_small img {
  border: 4px solid transparent;
  transition: border-color 0.2s linear 0s;
  border-radius: 3px;
}

.avatar_small:hover img, .avatar_small.active img {
  border-color: #f58a52;
}

#avatar_selection {
  background: url(../img/avatar-selection-bg.jpg) no-repeat scroll;
  height: 100%;
}

.avatars_grid {
  margin-top: 55px;
  position: relative;
  width: 660px;
  max-width: 46.3%;
  float: right;
  padding-right: 10%;
}

.avatars_grid:after {
  background: url(../img/avatar-terminator.png) scroll repeat;
  width: 10px;
  height: 100%;
  content: '';
  position: absolute;
  top: 0;
  right: 8%;
  z-index: 1;
}

.avatars_grid h1 {
  text-align: center;
  color: #b4c3c4;
  position: absolute;
  width: 44%;
  min-width: 220px;
  left: 17.2%;
  top: -9.2%;
  padding: 3% 8%;
  box-sizing: border-box;
  font-size: 2.3em;
  text-shadow: -2px 2px 2px rgba(0, 0, 0, 0.75);
}

.avatar_descr {
  width: 393px;
  margin: 10px auto;
  max-width: 100%;
}

.avatar_default_name {
  background: url(../img/avatar-name-bg.png) no-repeat scroll;
  font-size: 2.7em;
  text-shadow: 1px 1px 2px rgba(20, 20, 20, 0.8);
  padding: 14.5% 0 0;
  text-align: center;
  height: 165px;
  max-height: 10vw;
  width: 393px;
  max-width: 100%;
  box-sizing: border-box;
  color: #fff;
}

#avatar_descr4 .avatar_default_name {
  font-size: 2.1em;
  padding: 16% 0 0;
}

.avatar_type {
  text-align: center;
  color: #b4c3c4;
  font-size: 2.3em;
  text-shadow: -2px 2px 2px rgba(0, 0, 0, 0.75);
}

.avatar_details {
  background: rgba(0, 0, 0, 0.75);
  border-radius: 8px;
  color: #fff;
  font-size: 1.7em;
  width: 330px;
  max-width: 96%;
  margin: 0 auto;
  padding: 40px 22px;
  position: relative;
  box-sizing: border-box;
}

.avatar_details:before, .avatar_details:after {
  background: url(../img/avatar_descr_border.png) no-repeat scroll;
  position: absolute;
  left: 20px;
  display: block;
  content: '';
  width: 293px;
  height: 17px;
  max-width: 86%;
  background-size: 100% auto;
}

.avatar_details:before {
  top: 17px;
}

.avatar_details:after {
  bottom: 17px;
}

.avatar_details p {
  margin-bottom: 0.8em;
}

.avatar_details p:last-child {
  margin-bottom: 0.3em;
}

.avatar_large_wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
}

.avatar_large img {
  max-height: 100%;
}

.hexagon {
  overflow: hidden;
  visibility: hidden;
  -webkit-transform: rotate(120deg);
  -moz-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  -o-transform: rotate(120deg);
  transform: rotate(120deg);
  width: 255px;
  max-width: 38.7%;
  height: 221px;
  max-height: 11.5vw;
  float: left;
}

.hexagon:nth-child(3n+2) {
  margin-left: -10%;
}

.hexagon:nth-child(2) {
  margin-top: 16.8%;
}

.hexagon:nth-child(3n+3) {
  margin-top: -16.8%;
  margin-left: -9.8%;
}

.hexagon:nth-child(3) {
  margin-top: 0;
}

.hexagon:nth-child(3n+4) {
  margin-top: -16.8%;
  clear: left;
}

.hexagon:last-child {
  margin-top: -16.8%;
  margin-left: 19.4%;
}

.hexagon-in1 {
  overflow: hidden;
  width: 100%;
  height: 100%;
  -webkit-transform: rotate(-60deg);
  -moz-transform: rotate(-60deg);
  -ms-transform: rotate(-60deg);
  -o-transform: rotate(-60deg);
  transform: rotate(-60deg);
}

.hexagon-in2 {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: 50%;
  visibility: visible;
  -webkit-transform: rotate(-60deg);
  -moz-transform: rotate(-60deg);
  -ms-transform: rotate(-60deg);
  -o-transform: rotate(-60deg);
  transform: rotate(-60deg);
}

#avatar_large3 img {
  margin-left: -9%;
}

#avatar_large5 img {
  margin-left: -15%;
}

#avatar_large6 img {
  margin-left: -30%;
}

#avatar_large7 img {
  margin-left: 6%;
}

#question_avatar1 img {
  margin-left: 24%;
}

#question_avatar3 img {
  margin-left: 3%;
}

#question_avatar4 img {
  margin-left: 10%;
}

#question_avatar5 img {
  margin-left: 5%;
}

#question_avatar6 {
  max-width: 52%;
}

#question_avatar6 img {
  margin-left: 21%;
}

#question_avatar7 img {
  margin-left: -8%;
}

#question_avatar8 img {
  margin-left: 15%;
}

/*------------------------------------------------------------------
  11. ICONS
  -------------------------------------------------------------------*/

@font-face {
  font-family: 'fontello';
  src: url('../fonts/fontello.eot?51742766');
  src: url('../fonts/fontello.eot?51742766#iefix') format('embedded-opentype'), url('../fonts/fontello.woff2?51742766') format('woff2'), url('../fonts/fontello.woff?51742766') format('woff'), url('../fonts/fontello.ttf?51742766') format('truetype'), url('../fonts/fontello.svg?51742766#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^='icon-']:before, [class*=' icon-']:before {
  font-family: 'fontello';
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  margin-left: 0.2em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-ok:before {
  content: '\e800';
}

/* '' */

.icon-cancel:before {
  content: '\e801';
}

/* '' */

.icon-plus:before {
  content: '\e802';
}

/* '' */

.icon-plus-1:before {
  content: '\e803';
}

/* '' */

.icon-info:before {
  content: '\e804';
}

/* '' */

.icon-to-end:before {
  content: '\e805';
}

/* '' */

.icon-mail:before {
  content: '\e808';
}

/* '' */

.icon-mail-1:before {
  content: '\e809';
}

/* '' */

.icon-instagram:before {
  content: '\e80e';
}

/* '' */

.icon-arrows-cw:before {
  content: '\e80f';
}

/* '' */

.icon-volume-high:before {
  content: '\e810';
}

/* '' */

.icon-volume-off:before {
  content: '\e811';
}

/* '' */

.icon-resize-full:before {
  content: '\e812';
}

/* '' */

.icon-resize-small:before {
  content: '\e813';
}

/* '' */

.icon-adult:before {
  content: '\e814';
}

/* '' */

.icon-spin6:before {
  content: '\e815';
}

/* '' */

.icon-user:before {
  content: '\e816';
}

/* '' */

.icon-trash:before {
  content: '\e817';
}

/* '' */

.icon-floppy:before {
  content: '\e818';
}

/* '' */

/*------------------------------------------------------------------
  12. EXTRAS
  -------------------------------------------------------------------*/

.relative {
  position: relative;
}

.fullwidth {
  padding: 40px 7.5% 30px;
  text-align: justify;
  font-size: 1.43em;
  color: #4b4b4d;
  line-height: 1.4em;
}

html, body, #login, #headphones-loader, .container, main, .page.visible, .question_outer, .question_wrapper, .question_col, .answers_wrapper, .question_main_wrapper, .avatar_large, #world-map-container, .book_container {
  height: 56.3vw;
}

.overflowed .container {
  overflow-y: auto;
  overflow-x: hidden;
  height: 57.8vw;
}

.overflowed .container.no-overflow {
  overflow-y: hidden;
  height: 56.3vw;
}

.map-container, .question_outer, .question_wrapper, .question_main_wrapper, .question_main, .answer_draggables, .answer, .answers, .img-hover-btn {
  position: relative;
}

.piece {
  background: url(../img/piece.png) scroll no-repeat transparent;
  width: 82px;
  height: 85px;
  max-width: 5.76%;
  position: absolute;
  left: 0%;
  top: -3%;
  -webkit-transition: all 0.5s linear;
  -moz-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  transition: all 0.5s linear;
  z-index: 8;
}

.dash {
  background: url(../img/mid-dash.png) scroll no-repeat transparent;
  width: 26px;
  height: 17px;
  max-width: 1.36%;
  position: absolute;
  left: 0;
  top: 0;
}

.dash.end {
  background-image: url(../img/end-dash.png);
  width: 48px;
  height: 36px;
  max-width: 2.5%;
}

.cloud {
  background: url(../img/cloud.png) scroll no-repeat transparent;
  width: 600px;
  height: 337px;
  max-width: 30%;
  position: absolute;
  right: 0;
  top: 0;
}

.cloud1 {
  left: -6%;
  top: -3%;
}

.cloud2 {
  left: -1%;
  top: 0;
}

.cloud3 {
  left: -4%;
  top: 13%;
}

.cloud4 {
  left: 11%;
  top: -2%;
}

.cloud5 {
  left: 10%;
  top: 0%;
}

.cloud6 {
  left: -4%;
  top: 10%;
}

.cloud7 {
  left: 18%;
  top: 16%;
}

.cloud8 {
  left: 38%;
  top: 27%;
}

.cloud9 {
  left: 28%;
  top: 34%;
}

.cloud10 {
  left: 38%;
  top: 42%;
}

.cloud11 {
  left: 26%;
  top: 43%;
}

.cloud12 {
  left: 42%;
  top: 40%;
}

.cloud13 {
  left: 41%;
  top: 28%;
}

.cloud14 {
  left: 35%;
  top: 28%;
}

.cloud15 {
  left: 24%;
  top: -4%;
}

.cloud16 {
  left: 34%;
  top: -6%;
}

.cloud17 {
  left: 25%;
  top: -2%;
}

.cloud18 {
  left: 37%;
  top: 5%;
}

.cloud19 {
  left: 38%;
  top: -5%;
}

.cloud20 {
  left: 27%;
  top: 3%;
}

.cloud21 {
  left: 40%;
  top: -5%;
}

.cloud22 {
  left: 59%;
  top: -3%;
}

.cloud23 {
  left: 73%;
  top: -4%;
}

.cloud24 {
  left: 57%;
  top: 4%;
}

.cloud25 {
  left: 71%;
  top: -3%;
}

.cloud26 {
  left: 68%;
  top: 6%;
}

.cloud27 {
  left: 73%;
  top: 7%;
}

.cloud28 {
  left: 56%;
  top: 7%;
}

.cloud29 {
  left: 58%;
  top: 37%;
}

.cloud30 {
  left: 67%;
  top: 41%;
}

.cloud31 {
  right: -5%;
  top: 40%;
}

.cloud32 {
  right: -4%;
  top: 33%;
}

.cloud33 {
  right: 0;
  top: 38%;
}

.cloud34 {
  right: 10%;
  top: 26%;
}

.cloud35 {
  right: -5%;
  top: 27%;
}

.cloud36 {
  left: 58%;
  top: 62%;
}

.cloud37 {
  left: 67%;
  top: 61%;
}

.cloud38 {
  right: -5%;
  top: 63%;
}

.cloud39 {
  right: -4%;
  top: 72%;
}

.cloud40 {
  right: 0;
  top: 78%;
}

.cloud41 {
  right: 10%;
  top: 76%;
}

.cloud42 {
  right: -5%;
  top: 60%;
}

.cloud43 {
  left: 28%;
  top: 58%;
}

.cloud44 {
  left: 41%;
  top: 60%;
}

.cloud45 {
  left: 24%;
  top: 75%;
}

.cloud46 {
  left: 40%;
  top: 73%;
}

.cloud47 {
  left: 30%;
  top: 59%;
}

.cloud48 {
  left: 26%;
  top: 60%;
}

.cloud49 {
  left: 45%;
  top: 55%;
}

.cloud50 {
  left: -6%;
  top: 60%;
}

.cloud51 {
  left: 7%;
  top: 60%;
}

.cloud52 {
  left: -9%;
  top: 70%;
}

.cloud53 {
  left: 6%;
  top: 73%;
}

.cloud54 {
  left: 9%;
  top: 71%;
}

.cloud55 {
  left: -12%;
  top: 76%;
}

.cloud56 {
  left: 10%;
  top: 59%;
}

.cloud57 {
  left: -6%;
  top: 27%;
}

.cloud58 {
  left: -7%;
  top: 41%;
}

.cloud59 {
  left: -2%;
  top: 41%;
}

.cloud60 {
  left: 6%;
  top: 29%;
}

.cloud61 {
  left: -9%;
  top: 28%;
}

.cloud62 {
  left: 9%;
  top: 27%;
}

.cloud63 {
  left: 13%;
  top: 41%;
}

.cloud64, .cloud65, .cloud66, .cloud67, .cloud68, .cloud69, .cloud70, .cloud71, .cloud72, .cloud73, .cloud74, .cloud75, .cloud76, .cloud77, .cloud78, .cloud79, .cloud80, .cloud81, .cloud82, .cloud83, .cloud84, .cloud85, .cloud86, .cloud87, .cloud88, .cloud89 {
  display: none;
}

.check-radio {
  user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  display: block;
  height: 32px;
  color: #eee;
  font-size: 2.2em;
  margin: 1% 0 1.5% 16.8%;
}

.check-radio>input {
  visibility: hidden;
  position: absolute;
}

.check-radio>.img-btn {
  display: inline-block;
  vertical-align: middle;
  width: 32px;
  height: 32px;
  opacity: 0.6;
  margin-right: 1.8%;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.check-radio>.img-btn, .check-radio>.img-btn:after {
  background-image: url(../img/radio-bg.png);
}

.check-radio:hover>.img-btn {
  opacity: 1;
}

.check-radio:hover>.img-btn:after {
  opacity: 0;
}

.check-radio>input:checked+.img-btn, .check-radio>input:checked+.img-btn:after {
  opacity: 1;
}

.question_outer {
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: scroll;
  color: #fff;
}

.question_avatar {
  position: absolute;
  bottom: 0;
  left: 0;
  max-width: 30%;
  max-height: 100%;
}

.question_avatar img {
  display: block;
}

.player_name {
  color: #fff;
  font-size: 2.572em;
  position: absolute;
  bottom: 8%;
  left: 1.4%;
  text-shadow: 1px 1px 2px rgba(100, 100, 100, 0.4);
  padding: 1.2% 0 0;
  text-align: center;
  height: 93px;
  width: 425px;
  max-width: 22%;
  max-height: 18%;
  background: transparent url('../img/player-name-bg-v2.png') no-repeat scroll 0 0;
  box-sizing: border-box;
}

.leaderboard_position {
  position: absolute;
  bottom: 20%;
  left: 1.4%;
  text-shadow: 1px 1px 2px rgba(100, 100, 100, 0.4);
  padding: 1% 0 0;
  text-align: center;
  height: 93px;
  width: 425px;
  max-width: 22%;
  max-height: 18%;
  background: transparent url('../img/headphones-box-bg.png') no-repeat center;
  box-sizing: border-box;
  background-size: contain;
  h3, h2 {
    color: #fff;
  }
  h3 {
    font-size: 1.2em;
  }
}

.player_name_4 {
  font-size: 2.1em;
  padding: 4% 0 0;
}

.progress_bar {
  background: url(../img/progress-bar-v2.png) no-repeat scroll top center;
  width: 100%;
  margin: 0;
  height: 100%;
  position: relative;
}

.progress-title {
  top: 17%;
  font-size: 14px;
  text-align: center;
  position: absolute;
  width: 100%;
  left: 0;
  font-size: 14px;
}

.progress_pointer_wrap {
  padding: 0 7.7% 0;
  width: 100%;
  height: 6.5px;
  box-sizing: border-box;
  font-size: 14px;
  top: 66.5%;
  position: absolute;
}

.progress_pointer {
  display: inline-block;
  width: 0;
  height: 100%;
  z-index: 10;
  max-width: 100%;
  -moz-transition: opacity 1s ease-in;
  -webkit-transition: opacity 1s ease-in;
  -o-transition: opacity 1s ease-in;
  transition: opacity 1s ease-in;
  background-color: transparent;
  position: relative;
}

.progress_pointer.passed {
  background-color: #5acb0a;
  box-shadow: 0 0 6px rgba(90, 203, 10, 0.6);
}

.progress_pointer.current {
  background-color: #6eee12;
  box-shadow: 0 0 22px rgba(110, 238, 18, 1);
}

.progress_pointer:before {
  content: attr(data-cnt);
  display: block;
  background: url(../img/circle-stage.png) no-repeat scroll center;
  width: 36px;
  height: 36px;
  left: 35%;
  position: absolute;
  text-align: center;
  padding: 7.8% 0;
  box-sizing: border-box;
  text-shadow: -1px 1px 3px rgba(0, 0, 0, 0.8);
  font-weight: 700;
  top: -526%;
  background-size: 100% auto;
  max-width: 1.875vw;
  max-height: 1.875vw;
}

.progress_pointer.passed:before, .progress_pointer.current:before {
  background-image: url(../img/circle-stage-green.png);
}

.progress_pointer:after {
  content: '';
  display: block;
  background: #fffef8;
  width: 1px;
  height: 400%;
  margin-top: -11px;
}

.progress_pointer:first-child:after {
  display: none;
}

.progress_bar h4 {
  color: #fff;
  font-size: 1.43em;
  margin: -6px 0 0;
  text-align: center;
}

.question_wrapper {
  z-index: 8;
}

.question_main_wrapper {
  width: 809px;
  margin: 0 0 0 33.5%;
  max-width: 64.1%;
}

.progress_bar_outer {
  margin: 10px auto 4.6%;
  height: 126px;
  max-height: 11.7%;
  width: 546px;
  max-width: 67.5%;
}

.question_main {
  width: 100%;
  height: 635px;
  max-height: 58.8%;
  max-width: 100%;
  background: url(../img/question-bg-v2.png) scroll no-repeat;
}

.question_main, .text_container {
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.question_main h1 {
  /* background: url(../img/question-header-bg.png) scroll no-repeat; */
  font-weight: 700;
  color: #fff;
  text-align: center;
  padding: 8px 0;
}

#narration_end_arrow {
  background: url(../img/question-after-bg.png) scroll no-repeat;
  max-width: 30.9%;
  max-height: 80.2%;
  width: 226px;
  height: 867px;
  content: '';
  position: absolute;
  top: 9.2%;
  right: -17%;
  z-index: 1;
}

.text_container {
  height: 100%;
}

.question-island {
  text-align: center;
  font-size: 1.8em;
  height: 80px;
  line-height: 80px;
}

.question_text_container {
  height: 360px;
  max-height: 77%;
  overflow: hidden;
  padding: 6.2% 10% 0 10.5%;
}

.question_text {
  color: #fff;
  padding: 0;
  font-size: 1.4em;
}

.question_text p {
  padding: 0 6% 18px 2%;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}

.question_text .narration {
  font-style: italic;
}

.question_text .final_question {
  font-size: 1.3em;
  font-weight: bold;
  padding: 0 4% 0 2%;
  text-align: center;
}

.answers_wrapper {
  padding: 2% 0 0 1.5%;
  box-sizing: border-box;
}

.answer_draggables {
  height: 7.4%;
}

.answers {
  height: 79.6%;
}

.answer_submit_wrapper {
  height: 13%;
}

.answer {
  width: 100%;
  height: 225px;
  max-height: 23.4%;
  font-size: 1.3em;
  line-height: 1.3em;
  padding: 0;
  margin: 2.8% 0 0;
  box-sizing: border-box;
  background: url(../img/reply-bg.png) scroll no-repeat;
  background-size: auto 100%;
}

.answer .answer_inner {
  height: 76.2%;
  padding: 3.6% 4% 4% 4.2%;
  width: 70.2%;
}

.answer .answer_txt {
  border-radius: 18px;
  height: 100%;
  padding: 1.5% 3%;
  box-sizing: border-box;
  -moz-transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

.answer.hovered .answer_txt {
  -webkit-box-shadow: 0px 0px 28px 10px rgba(239, 137, 5, 0.6) inset;
  -moz-box-shadow: 0px 0px 28px 10px rgba(239, 137, 5, 0.6) inset;
  box-shadow: 0px 0px 28px 10px rgba(239, 137, 5, 0.6) inset;
}

.answer.selected .answer_txt {
  -webkit-box-shadow: 0 0 28px 10px rgba(112, 245, 18, 0.9) inset;
  -moz-box-shadow: 0 0 28px 10px rgba(112, 245, 18, 0.9) inset;
  box-shadow: 0 0 28px 10px rgba(112, 245, 18, 0.9) inset;
}

.answer.rejected .answer_txt {
  -webkit-box-shadow: 0 0 28px 10px rgba(209, 22, 18, 0.9) inset;
  -moz-box-shadow: 0 0 28px 10px rgba(209, 22, 18, 0.9) inset;
  box-shadow: 0 0 28px 10px rgba(209, 22, 18, 0.9) inset;
}

.answer:before, .answer.rejected:after, .answer.selected:after {
  display: block;
  content: '';
  position: absolute;
  top: 50%;
  right: 5%;
  width: 65px;
  height: 65px;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}

.answer:before {
  background-image: url(../img/coins-placeholder.png);
}

.answer.selected:after, .answer.rejected:after {
  opacity: 0.8;
}

.answer.selected:after {
  background-image: url(../img/select-coin.png);
}

.answer.rejected:after {
  background-image: url(../img/reject-coin.png);
}

.answer_draggables img {
  max-width: 26%;
  vertical-align: middle;
  margin-right: 1.5%;
  cursor: move;
  z-index: 9;
}

.answer_draggables h4 {
  display: inline;
  color: #fff;
  text-shadow: -1px 1px 3px rgba(0, 0, 0, 0.4);
  vertical-align: middle;
  font-size: 1.6em;
}

.soundControl, .muteControl, .fullscreenControl, .helper-btn {
  position: fixed;
  z-index: 9;
  font-size: 1.4em;
}

.soundControl {
  top: 16px;
}

.muteControl {
  top: 78px;
}

.fullscreenControl {
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  padding: 0.5% 0.3%;
  z-index: 9999;
}

.fullscreenControl i {
  color: #fff;
}

.narration_actions {
  position: absolute;
  bottom: -16.8%;
  left: 0;
  width: 72%;
  height: 13.4%;
  padding: 12px 14%;
}

.book_container, #headphones-loader {
  position: relative;
  width: 100%;
}

.book_narration_actions {
  bottom: 15%;
  left: 39%;
  width: 26%;
  height: 5.4%;
  padding: 0;
  z-index: 9;
}

.book_narration_actions .replayNarration {
  width: 143px;
}

.book_narration_actions .skipNarration {
  width: 91px;
}

.book_narration_actions .continueButton {
  width: 151px;
}

.book_narration_actions .img-btn {
  height: 100%;
  max-height: 47px;
}

.book_narration_actions .img-btn:hover {
  opacity: 0.9;
}

.book_narration_actions .img-btn:after {
  display: none;
}

.avatar_name_form {
  max-width: 300px;
}

.avatar_name_form label {
  display: block;
  margin: 20px 0 2px;
  border-top: 1px solid #ccc;
  padding-top: 20px;
  font-weight: 300;
}

.avatar_name_form input {
  border-color: transparent;
  font-size: 1.1em;
  -moz-transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

.avatar_name_form input:hover, .avatar_name_form input:focus {
  border-color: #ddd;
  box-shadow: 1px 1px 6px 0px rgba(0, 0, 0, 0.1);
}

#fontControls {
  background: url(../img/font-size_dropdown.png);
  text-align: center;
  position: absolute;
  left: 15px;
  top: 203px;
  width: 171px;
  height: 177px;
  z-index: 9;
}

#fontControls a {
  color: #fff;
  display: block;
  padding: 8px 5px;
  text-shadow: -1px 1px 3px rgba(0, 0, 0, 0.4);
  font-size: 1.6em;
  line-height: 1.55em;
}

#fontControls a:hover, #fontControls a.selected {
  color: #ffc800;
}

.fontsmall {
  font-size: 0.85em;
}

.fontmedium {
  font-size: 1em;
}

.fontlarge {
  font-size: 1.15em;
}

.img-btn {
  display: block;
  text-indent: -9999px;
  border: none;
  background-color: transparent;
  padding: 0;
}

.helper-btn {
  width: 58px;
  height: 57px;
  left: 15px;
}

.helper-btn:hover:after, #help-button:hover, #journal-button:hover {
  box-shadow: -1px -1px 18px rgba(255, 200, 0, 1);
}

.img-hover-btn {
  background-position: 0 0;
}

.img-hover-btn:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  background-position: 0 100%;
  opacity: 0;
  display: block;
  width: 100%;
  height: 100%;
  -moz-transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

.img-hover-btn:hover:after {
  opacity: 1;
}

#help-button {
  background-image: url(../img/help-button.png);
}

#journal-button {
  background-image: url(../img/journal-button.png);
}

#sound-button, #sound-button:after {
  background-image: url(../img/sound-button.png);
}

#mute-button, #mute-button:after {
  background-image: url(../img/mute-button.png);
}

#viewbg-button, #viewbg-button:after {
  background-image: url(../img/view-bg-button.png);
}

#sound-button.muted:after, #mute-button.muted:after, #viewbg-button:after, #viewbg-button.isolated:after {
  opacity: 1;
}

#fontsize-button {
  background-image: url(../img/fontsize-button-mobile.png);
  top: 141px;
  height: 60px;
}

#fontsize-button:hover {
  box-shadow: 1px -4px 18px rgba(255, 200, 0, 1);
}

#help-button {
  top: 141px;
}

.replayNarration {
  width: 201px;
  max-width: 38%;
  height: 69px;
  max-height: 88.3%;
}

.replayNarration, .replayNarration:after {
  background-image: url(../img/localization/en/replay-button.png);
}

.skipNarration {
  width: 121px;
  max-width: 24.3%;
  height: 69px;
  max-height: 91%;
}

.skipNarration, .skipNarration:after {
  background-image: url(../img/localization/en/skip-button.png);
}

.continueButton {
  width: 201px;
  max-width: 41.5%;
  height: 69px;
  max-height: 91%;
}

.continueButton, .continueButton:after {
  background-image: url(../img/localization/en/continue-button.png);
}

#answer_submit {
  max-width: 34.8%;
  max-height: 46.6%;
  width: 211px;
  height: 69px;
  margin: 1.6% 0 0 22%;
  opacity: 0;
}

#answer_submit, #answer_submit:after {
  background-image: url(../img/localization/en/submit-button.png);
}

#submitLogin {
  margin: 1.6% auto 0;
  max-width: 40.5%;
  max-height: 17.3%;
  width: 269px;
  height: 88px;
}

#submitLogin, #submitLogin:after {
  background-image: url(../img/login-button.png);
}

#submitLang {
  margin: 4% auto 0;
  max-width: 46.8%;
  max-height: 24.4%;
  width: 255px;
  height: 102px;
}

#submitLang, #submitLang:after {
  background-image: url(../img/select-lang-btn.png);
}

#avatar_submit {
  margin: 8% 0 0 29.6%;
  max-width: 38.8%;
  width: 255px;
  height: 97px;
  max-height: 5vw;
}

#avatar_submit, #avatar_submit:after {
  background-image: url(../img/localization/en/play-button.png);
}

.mCS-inset-dark.mCSB_scrollTools {
  width: 4.35%;
  right: 2px;
}

.mCS-inset-dark.mCSB_scrollTools .mCSB_draggerRail, .mCSB_scrollTools {
  width: 33px;
}

.mCS-inset-dark.mCSB_scrollTools .mCSB_draggerRail {
  background: transparent url('../img/scroll-bar.png') no-repeat scroll 0 0;
  opacity: 0.3;
}

.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 100% !important;
  background: url(../img/scroll-bar-gem.png) no-repeat transparent !important;
  background-size: 100% auto !important;
  height: 33px !important;
  margin: 3px 1px;
}

.mCSB_scrollTools .mCSB_dragger {
  height: 33px !important;
}

.help_overlay, .full_overlay {
  z-index: 97;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
}

.help_overlay {
  box-shadow: 0 0 20px 10px rgba(255, 200, 0, 1);
  border-radius: 8px;
}

.help_overlay a {
  width: 96px;
  height: 95px;
  margin: 0 auto;
  background: transparent url('../img/info-button.png') no-repeat scroll;
  max-width: 13.4%;
  left: 44.5%;
  position: absolute;
  top: 0;
}

.help_txt {
  width: 244px;
  height: 253px;
  max-height: 13.2vw;
  background: transparent url('../img/info-button-dropdown.png') no-repeat scroll;
  max-width: 34%;
  box-sizing: border-box;
  padding: 3.4% 3.2%;
  overflow: hidden;
  font-size: 0.9em;
  position: absolute;
  top: 55.5%;
  left: 55.6%;
}

.answers_wrapper .help_txt {
  max-width: 39.7%;
}

.help_txt p:first-child {
  font-size: 1.2em;
  font-weight: bold;
}

.help_txt p {
  margin-bottom: 0.2em;
}

#progress_help {
  height: 14%;
  z-index: 999;
}

#progress_help a {
  top: 14%;
}

#question_help a {
  top: 55%;
}

#question_help .help_txt {
  top: 68%;
}

#answer_draggables_help {
  z-index: 99;
}

#answer_draggables_help .help_txt {
  top: 86.5%;
}

#answers_help a {
  top: 30%;
}

#answers_help .help_txt {
  top: 38%;
}

#end_screen {
  background: url(../img/congratulations-bg.jpg) no-repeat scroll;
  text-align: center;
  padding: 7% 0;
}

.congratulations-box {
  background: url(../img/congratulations-box-bg.png) no-repeat scroll;
  width: 1118px;
  height: 691px;
  max-width: 70%;
  max-height: 63.9%;
  margin: 16.4% auto 0;
}

.congratulations-box p {
  padding: 3% 18%;
  height: 20%;
  overflow: hidden;
}

.congratulations-box a {
  color: #e46c0a;
  cursor: pointer;
}

#book_screen {
  background: url(../img/book-screen-bg.jpg) no-repeat;
}

.js #book {
  margin-left: -10000px;
}

#book.wowbook {
  left: 10.6%;
  margin: auto;
  position: absolute;
  top: 2%;
  max-width: 55.2%;
}

#book.wowbook.fullscreen {
  top: 8.2%;
}

.wowbook-page-content {
  background: #c3873e;
  background: -moz-linear-gradient( -45deg, #c3873e 0%, #d3ac51 30%, #e2c26b 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient( -45deg, #c3873e 0%, #d3ac51 30%, #e2c26b 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, #c3873e 0%, #d3ac51 30%, #e2c26b 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c3873e', endColorstr='#e2c26b', GradientType=1);
  /* IE6-9 fallback on horizontal gradient */
  padding: 6% 3% 16% 7%;
  max-width: 100%;
  max-height: 100%;
  font-size: 1.25em;
  color: #111111;
}

.wowbook-page-content p {
  margin-bottom: 0.5em;
}

.wowbook-zoomcontent, .wowbook-zoomwindow {
  max-width: 100%;
}

.wowbook-origin {
  max-height: 80%;
}

.wowbook-page {
  max-width: 50%;
  max-height: 100%;
}

.wowbook-page.wowbook-right {
  left: 50% !important;
}

.wowbook-page-number {
  position: absolute;
  bottom: 0;
  right: 0;
}

.firstcharacter {
  float: left;
  color: #903;
  font-size: 5.36em;
  line-height: 60px;
  padding-top: 4px;
  padding-right: 8px;
  padding-left: 3px;
  font-family: Georgia;
}

video#game_video {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  background: url(../img/loader.gif) no-repeat center center #000;
}

.tooltip {
  display: inline-block;
  transition: all 0.4s;
}

.tooltip.inited {
  border-bottom-width: 2px;
  border-bottom-style: dashed;
  border-bottom-color: #ffff00;
  border-bottom-color: rgba(255, 255, 0, 0.75);
}

#book .tooltip.inited {
  border-bottom-color: #111;
  border-bottom-color: rgba(17, 17, 17, 0.75);
}

.tooltip.inited:hover {
  border-bottom-style: solid;
}

#resolutionOverlay {
  position: fixed;
  left: 0;
  bottom: 0;
  background: #333;
  color: #fff;
  padding: 1.6% 3%;
  font-size: 1.2em;
  line-height: 1.2em;
  width: 100%;
  box-sizing: border-box;
  z-index: 999;
}

#orientationRotateMsg {
  background-color: #404040;
  height: 100%;
  left: 0;
  top: 0;
  position: fixed;
  width: 100%;
  z-index: 150;
  background-position: center center;
  background-repeat: no-repeat;
}

#orientationRotateMsg p {
  text-align: center;
  color: #fff;
  position: absolute;
  width: 100%;
  bottom: 15%;
  font-size: 1.5em;
}

.hidden {
  display: none;
}

#login, .piece, .cloud, .player_name, .question_main, .progress_bar, .answer:before, .answer.rejected:after, .answer.selected:after, .img-btn, .img-hover-btn:after, .help_overlay a, .login_container, .lang_wrap, .lang_header, .login_logo, .login_container input, .headphones-box, #end_screen, #book_screen, .congratulations-box, .dash, .help_txt, #world-map-container, .hexagon-in2, .avatar_default_name {
  background-size: 100% auto;
  background-repeat: no-repeat;
}

.bg_screen {
  background-position: center;
}

.overflowed {
  background-color: transparent;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.overflowed .bg_screen {
  /* background: none !important; */
  position: relative;
}

.overflowed .question_avatar img {
  max-height: 55vw;
}

.overflowed #book.wowbook.variedRatio {
  left: 5.6%;
  max-width: 62.2%;
}

.overflowed #book.wowbook.fullscreen.variedRatio {
  top: 9.5%;
}

.overflowed .book_narration_actions {
  bottom: 20%;
}

.overflowed .variedRatio~.book_narration_actions {
  bottom: 5.2%;
  width: 28%;
  height: 6.9%;
}

.overflowed #narration_end_arrow {
  right: -16%;
}

.flex-container {
  display: flex;
  height: 100%;
  flex-wrap: wrap;
}

.flex-container>.item {
  flex-basis: 33.33%;
}

.world-background {
  background-image: url('../img/world_map/world_map_background.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.island-vortex {
  position: relative;
}

.island-vortex .cloud {
  position: absolute;
  top: -35%;
  right: -35%;
  bottom: -35%;
  left: -35%;
  width: 170%;
  height: 170%;
}

.island-order-1 {
  order: 1;
}

.island-order-2 {
  order: 2;
}

.island-order-3 {
  order: 3;
}

.island-order-4 {
  order: 6;
}

.island-order-placeholder {
  order: 5;
}

.island-order-5 {
  order: 9;
}

.island-order-6 {
  order: 8;
}

.island-order-7 {
  order: 7;
}

.island-order-8 {
  order: 4;
}

.bg-isolated .soundControl, .bg-isolated #muteNarration, .bg-isolated #fontsize-button, .bg-isolated #fontControls, .bg-isolated .question_wrapper, .bg-isolated .question_avatar, .bg-isolated .player_name, .bg-isolated #help-button, .bg-isolated #journal-button {
  display: none;
}

.bg-isolated #viewbg-button {
  left: 28px;
}

.journal-wrapper .pages, .journal-wrapper .page-wrapper>div {
  max-width: 100%;
  max-height: 100%;
}

.journal-wrapper .journal-chapter {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.journal-wrapper .pages .journal-page {
  width: 50%;
  height: 100%;
  position: absolute;
}

.journal-wrapper .pages .page-left {
  left: 0;
  top: 0;
}

.journal-wrapper .pages .page-right {
  background-image: url(../img/journal/journal-page-top.png);
  background-size: 100% auto;
  background-repeat: no-repeat;
  right: .6%;
  height: 104.25%;
  top: -3%;
}

.journal-wrapper .pages-content {
  box-sizing: border-box;
  height: 100%;
}

.journal-wrapper .pages-content .content-inner {
  font-size: 1.2em;
  padding: 10%;
}

.journal-wrapper .pages-content h2 {
  text-align: center;
  margin: .6em 0 1em;
}

.journal-wrapper .pages-content h1 {
  text-align: center;
  margin: 2em 0;
}

.chapter-portrait-wrapper {
  background-image: url(../img/journal/ornament.png);
  background-size: 100% auto;
  background-repeat: no-repeat;
  margin: 7% auto;
  width: 405px;
  height: 670px;
  max-width: 60.6%;
  max-height: 63vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chapter-portrait {
  width: auto;
  max-height: 90%;
  margin: 0 auto;
  max-width: 84%;
}

.chapter-portrait-Alexandria {
  max-width: 140%;
  margin-left: 25%;
}

.journal-map .chapter-portrait {
  max-width: 94%;
}

.chapters-grid {
  z-index: 999;
  bottom: 3%;
  right: 4%;
  position: absolute;
  width: 42%;
  height: 40%;
}

.journal-characters .chapters-grid {
  width: 35%;
}

.chapters-grid-5 {
  left: 63.2%;
  bottom: 6%;
}

.chapter-hexagon {
  max-width: 24.8%;
  max-height: 7.1vw;
}

.chapter-hexagon:nth-child(3n+2) {
  margin-left: -6%;
}

.chapter-hexagon:nth-child(3), .chapter-hexagon:nth-child(3n+4) {
  margin-top: 0;
  clear: none;
}

.chapter-hexagon:nth-child(3n+3) {
  margin-top: -3.8%;
  margin-left: -6.2%;
}

.chapter-hexagon:nth-child(2) {
  margin-top: 10.8%;
}

.chapter-hexagon:nth-child(3) {
  margin-top: -0.1%;
}

.chapter-hexagon:nth-child(4) {
  margin-left: 12.5%;
}

.chapter-hexagon:nth-child(5) {
  margin-left: -99.2%;
  margin-top: 21.6%;
}

.chapter-hexagon:nth-child(7) {
  margin-top: -14.4%;
  margin-left: -6.1%;
}

.chapter-hexagon:nth-child(8) {
  margin-top: -25.3%;
  margin-left: 74.7%;
}

.chapters-grid-5 .chapter-hexagon:nth-child(4) {
  margin-left: -25%;
  margin-top: 21.7%;
}

.chapters-grid-5 .chapter-hexagon:nth-child(5) {
  margin-left: -62.2%;
  margin-top: 21.6%;
}

.journal-map .chapters-grid .hexagon-in2 {
  background-size: 85% auto
}

.journal-artifacts .chapters-grid .hexagon-in2 {
  background-size: 60% auto
}

.journal-artifacts .chapters-grid .chapter-hexagon:nth-child(4) .hexagon-in2 {
  background-size: 30% auto;
  -webkit-transform: rotate(-15deg);
  transform: rotate(-15deg);
}

/* LOCALIZATIONS */

/* BR */
.lang_br .replayNarration,
.lang_br .replayNarration:after {
  background-image: url(../img/localization/br/replay-button.png);
}

.lang_br .skipNarration,
.lang_br .skipNarration:after {
  background-image: url(../img/localization/br/skip-button.png);
}

.lang_br .continueButton,
.lang_br .continueButton:after {
  background-image: url(../img/localization/br/continue-button.png);
}

.lang_br #answer_submit,
.lang_br #answer_submit:after {
  background-image: url(../img/localization/br/submit-button.png);
}

.lang_br #avatar_submit,
.lang_br #avatar_submit:after {
  background-image: url(../img/localization/br/play-button.png);
}

@media only screen and (max-width: 1024px),
  only screen and (max-device-width: 1024px) {
    .lang_br .answer_draggables h4 {
    font-size: 1.7em;
  }
}

/* EL */
@import url('https://fonts.googleapis.com/css?family=Noto+Serif:400,700&subset=greek');
.lang_el,
.lang_el h1,
.lang_el h2,
.lang_el h3,
.lang_el h4,
.lang_el h5,
.lang_el h6 {
  font-family: 'Noto Serif', serif;
}

.lang_el .replayNarration,
.lang_el .replayNarration:after {
  background-image: url(../img/localization/el/replay-button.png);
}

.lang_el .skipNarration,
.lang_el .skipNarration:after {
  background-image: url(../img/localization/el/skip-button.png);
}

.lang_el .continueButton,
.lang_el .continueButton:after {
  background-image: url(../img/localization/el/continue-button.png);
}

.lang_el #answer_submit,
.lang_el #answer_submit:after {
  background-image: url(../img/localization/el/submit-button.png);
}

.lang_el #avatar_submit,
.lang_el #avatar_submit:after {
  background-image: url(../img/localization/el/play-button.png);
}

.lang_el .answer_draggables h4 {
  font-size: 1.4em;
}

.lang_el .avatar_details {
  font-size: 1.4em;
}

.lang_el .question_text {
  font-size: 1.33em;
}

.lang_el .congratulations-box {
  font-size: 1.6em;
}

/* ES */
.lang_es .replayNarration,
.lang_es .replayNarration:after {
  background-image: url(../img/localization/es/replay-button.png);
}

.lang_es .skipNarration,
.lang_es .skipNarration:after {
  background-image: url(../img/localization/es/skip-button.png);
}

.lang_es .continueButton,
.lang_es .continueButton:after {
  background-image: url(../img/localization/es/continue-button.png);
}

.lang_es #answer_submit,
.lang_es #answer_submit:after {
  background-image: url(../img/localization/es/submit-button.png);
}

.lang_es #avatar_submit,
.lang_es #avatar_submit:after {
  background-image: url(../img/localization/es/play-button.png);
}

@media only screen and (max-width: 1024px),
  only screen and (max-device-width: 1024px) {
    .lang_es .answer_draggables h4 {
    font-size: 1.7em;
  }
}

/* IT */
.lang_it .replayNarration,
.lang_it .replayNarration:after {
  background-image: url(../img/localization/it/replay-button.png);
}

.lang_it .skipNarration,
.lang_it .skipNarration:after {
  background-image: url(../img/localization/it/skip-button.png);
}

.lang_it .continueButton,
.lang_it .continueButton:after {
  background-image: url(../img/localization/it/continue-button.png);
}

.lang_it #answer_submit,
.lang_it #answer_submit:after {
  background-image: url(../img/localization/it/submit-button.png);
}

.lang_it #avatar_submit,
.lang_it #avatar_submit:after {
  background-image: url(../img/localization/it/play-button.png);
}

@media only screen and (max-width: 1024px),
  only screen and (max-device-width: 1024px) {
    .lang_it .answer_draggables h4 {
    font-size: 1.7em;
  }
}

/* TR */
.lang_tr .replayNarration,
.lang_tr .replayNarration:after {
  background-image: url(../img/localization/tr/replay-button.png);
}

.lang_tr .skipNarration,
.lang_tr .skipNarration:after {
  background-image: url(../img/localization/tr/skip-button.png);
}

.lang_tr .continueButton,
.lang_tr .continueButton:after {
  background-image: url(../img/localization/tr/continue-button.png);
}

.lang_tr #answer_submit,
.lang_tr #answer_submit:after {
  background-image: url(../img/localization/tr/submit-button.png);
}

.lang_tr #avatar_submit,
.lang_tr #avatar_submit:after {
  background-image: url(../img/localization/tr/play-button.png);
}


/* RU */
.lang_ru .replayNarration,
.lang_ru .replayNarration:after {
  background-image: url(../img/localization/ru/replay-button.png);
}

.lang_ru .skipNarration,
.lang_ru .skipNarration:after {
  background-image: url(../img/localization/ru/skip-button.png);
}

.lang_ru .continueButton,
.lang_ru .continueButton:after {
  background-image: url(../img/localization/ru/continue-button.png);
}

.lang_ru #answer_submit,
.lang_ru #answer_submit:after {
  background-image: url(../img/localization/ru/submit-button.png);
}

.lang_ru #avatar_submit,
.lang_ru #avatar_submit:after {
  background-image: url(../img/localization/ru/play-button.png);
}

.lang_ru .avatars_grid h1 {
  font-size: 2em;
}

/* KO */
.lang_ko .replayNarration,
.lang_ko .replayNarration:after {
  background-image: url(../img/localization/ko/replay-button.png);
}

.lang_ko .skipNarration,
.lang_ko .skipNarration:after {
  background-image: url(../img/localization/ko/skip-button.png);
}

.lang_ko .continueButton,
.lang_ko .continueButton:after {
  background-image: url(../img/localization/ko/continue-button.png);
}

.lang_ko #answer_submit,
.lang_ko #answer_submit:after {
  background-image: url(../img/localization/ko/submit-button.png);
}

.lang_ko #avatar_submit,
.lang_ko #avatar_submit:after {
  background-image: url(../img/localization/ko/play-button.png);
}

.lang_ko .avatars_grid h1 {
  font-size: 2em;
}

/* SL */
.lang_sl .replayNarration,
.lang_sl .replayNarration:after {
  background-image: url(../img/localization/sl/replay-button.png);
}

.lang_sl .skipNarration,
.lang_sl .skipNarration:after {
  background-image: url(../img/localization/sl/skip-button.png);
}

.lang_sl .continueButton,
.lang_sl .continueButton:after {
  background-image: url(../img/localization/sl/continue-button.png);
}

.lang_sl #answer_submit,
.lang_sl #answer_submit:after {
  background-image: url(../img/localization/sl/submit-button.png);
}

.lang_sl #avatar_submit,
.lang_sl #avatar_submit:after {
  background-image: url(../img/localization/sl/play-button.png);
}

.lang_sl .avatars_grid h1 {
  font-size: 2em;
}