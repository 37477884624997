@import "~vars";
@import "~mixins";

// To be included, global font loader
// @import '~assets/fonts/loader.scss';

// Normalizer font-family
$base-font-family: 'Open Sans', sans-serif;
@import '~normalize-scss/sass/normalize/_import-now.scss';

html {
  height: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
body {
  min-height: 100%;
  font-family: 'Open Sans', sans-serif;
  font-size: $-font-size-m;
  background-color: $-color-body;
  color: $-font-color-primary;
}

::selection {
  background-color: rgba($-color-orange, 0.7);
  color: $-color-primary;
}
::-moz-selection {
  background-color: rgba($-color-orange, 0.7);
  color: $-color-primary;
}

::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-thumb {
  background: rgba(#f9a11d, 0.7);
  border-radius: 4px;
}

::-webkit-scrollbar-track {
  background: $-color-grey;
  border-radius: 4px;
}

@import '_common.scss';

/* 
 * Old css files
 * To be broken down and replaced within vue components
 */
@import "old/animate.scss";
@import "old/style.scss";
@import "old/responsive.scss";
