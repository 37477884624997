$--breakpoints: (
  'xxs' : (max-width: 360px),
  'xs' : (max-width: 767px),
  'sm' : (min-width: 768px),
  'md' : (min-width: 992px),
  'lg' : (min-width: 1200px),
  'xl' : (min-width: 1920px)
);

/* Break-points */
@mixin res($key, $map: $--breakpoints) {
  @if map-has-key($map, $key) {
    @media only screen and #{inspect(map-get($map, $key))} {
      @content;
    }
  } @else {
    @warn "Undefeined points: `#{$map}`";
  }
}

@mixin x2 {
  @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2),
  only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
    @content;
  }
}

@mixin x3 {
  @media only screen and (-webkit-min-device-pixel-ratio: 3), only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1), only screen and (min-device-pixel-ratio: 3),
  only screen and (min-resolution: 288dpi), only screen and (min-resolution: 3dppx) {
    @content;
  }
}

@mixin placeholder {
  ::-webkit-input-placeholder {@content};
       :-ms-input-placeholder {@content};
           ::-moz-placeholder {@content};
            :-moz-placeholder {@content};
}

@mixin keyframe($name) {
  @-webkit-keyframes #{$name} { @content };
     @-moz-keyframes #{$name} { @content };
      @-ms-keyframes #{$name} { @content };
       @-o-keyframes #{$name} { @content };
          @keyframes #{$name} { @content };
}

@mixin animation($name, $d: 1s, $type: 'linear') {
  -webkit-animation: #{$name} #{$d} #{$type};
     -moz-animation: #{$name} #{$d} #{$type};
      -ms-animation: #{$name} #{$d} #{$type};
       -o-animation: #{$name} #{$d} #{$type};
          animation: #{$name} #{$d} #{$type};
}

@mixin transform($transforms) {
	-webkit-transform: $transforms;
	   -moz-transform: $transforms;
	    -ms-transform: $transforms;
	        transform: $transforms;
}

@mixin transition($transition...) {
  -moz-transition:    $transition;
  -o-transition:      $transition;
  -webkit-transition: $transition;
  transition:         $transition;
}